const skills = [
  'React',
  'React Native',
  'TypeScript',
  'Node',
  'GraphQL',
  'AWS',
  'Docker',
  'PostgreSQL',
  'ElasticSearch',
  'MongoDB',
  'NodeBB',
  'REST',
  'SQL',
  'Angular',
  'Git',
]

export default skills

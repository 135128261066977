const educations = [
  {
    school: 'Nackademin',
    start: '2011',
    end: '2013',
    education: 'Interaction design',
  },
]

export default educations
